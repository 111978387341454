import { mapActions, mapGetters } from 'vuex'

export default {
  created() {
    this.value = this.getParams[this.paramName]
  },
  methods: {
    ...mapActions({
      setParams: 'ly2/setFiltersAndLoadData',
    }),
  },
  computed: {
    ...mapGetters({
      getDictionaries: 'ly2/getDictionaries',
      getParams: 'ly2/getFilters',
    }),
    getItems() {
      return this.getDictionaries[this.paramName]
    },
    value: {
      get() {
        return this.getParams[this.paramName]
      },
      set(v) {
        this.setParams({ [this.paramName]: v })
      },
    },
  },
}
