import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{attrs:{"label":_vm.label,"items":_vm.items,"value":_vm.value,"item-value":"id","item-text":"name","selectAllItem":"","multiple":""},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VListItem,{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c(VListItemAction,[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.selectionText)+" ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }