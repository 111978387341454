<template>
  <MultiplySelectSelect label="Возрастная группа" :items="agesItemsAdapter" v-model="value" />
</template>

<script>
import dataProviderMixin from './selectDataProvider'
import MultiplySelectSelect from './controlTypes/MultiplySelectSelect'

export default {
  mixins: [dataProviderMixin],
  data: () => ({
    paramName: 'ageGroupIds',
  }),
  components: {
    MultiplySelectSelect,
  },
  /*
    Если во время работы интерпретировать отсутствие выбора как выбор всех,
    то возникает проблема в пользовательском сценарии: «снять выбор одной опции — поставить другую»
    Снимаешь выбор — выбирается все
    Однако, при начальной загрузке полезно заменить выбор ничего, на выбор всего
    Возможно стоит делать это также на выход из фильтра (если такое событие есть)
  */
  created() {
    /*
      В value всегда возвращается на один элемент больше, чем количество выбранных элементов в списке
      Чтобы проверить, что не выбран ни один, нужно сравнить длину value с 1
      Чтобы имитровать выбор всех нужно положить в массив на 1 больше чем длинна items
    */
    let values = this.getParams[this.paramName]
    // if (values.length === 1) {
    //   /*
    //     массив [0, 1, 2, ... 19]
    //     Несколько странное решение, но пока не понимаю как обыграть выбор всего
    //   */
    //   values = [...Array(this.agesItemsAdapter.length + 1).keys()]
    // }
    this.value = values
  },
  computed: {
    /*
      Не удается использовать встроенный в миксин метод getItems
      т.к. разные названия полей ageGroups — ageGroup
      Причем множественное число «наоборот» с другими параметрами (регионами и причинами)
    */
    agesItemsAdapter() {
      return this.getDictionaries.ageGroup.map(a => ({ ...a, name: a.comment }))
    },
  },
}
</script>
