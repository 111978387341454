/**
 * Схлопывает дипазоны возрастов в человеко-читаемый вид
 * @param {array} ages Массив со строками из диапазонов возрастов: ['5-9', '10-14']
 * @return {array} Массив со строками из диапазонов возрастов: ['5-14']
 */
export default function collapseAgeDiapasonsToString(ages) {
  if (!Array.isArray(ages)) throw new Error('В функцию collapseAgesDiapasons нужно передать массив')
  if (!ages.length) return []
  
  const BASE10 = 10
  
  const sortAgesDiapasons = (a, b) => {
    const fa = parseInt(a, BASE10)
    const fb = parseInt(b, BASE10)
    return fa - fb
  }
  
  const replaceFunction = match => {
    const prevEnd = parseInt(match.split(';')[0], BASE10)
    const nextStart = parseInt(match.split(';')[1], BASE10)
    return nextStart - prevEnd === 1 ? '' : match
  }

  return ages
    .sort(sortAgesDiapasons)
    .join(';')
    .replace(/^0;/, '0-0;')
    .replace(/\+$/, '-+')
    .replaceAll(/\d+;\d+-/g, replaceFunction)
    .replace(/^0-0;/, '0;')
    .replace(/-\+$/, '+')
    .split(';')
    .join(', ')
}