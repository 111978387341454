<template>
  <div>
    <AutocompleteSelect label="Причина" :items="oneReasonItemsAdapter" v-model="value" />
  </div>
</template>

<script>
import dataProviderMixin from './selectDataProvider'
import AutocompleteSelect from './controlTypes/AutocompleteSelect'

export default {
  mixins: [dataProviderMixin],
  data: () => ({
    paramName: 'reasonId',
  }),
  components: {
    AutocompleteSelect,
  },
  computed: {
    /*
      не удается использовать встроенный в миксин метод getItems
      т.к. разные названия полей reason — reasons
    */
    oneReasonItemsAdapter() {
      //      console.log(this.getDictionaries.reasons[0])
      return [...this.getDictionaries.reasons].filter(({ level }) => level === 1)
    },
  },
}
</script>
