<template>
  <div>
    <RegionsMap :options="options" :data="regionsMapData" @map-hover="onMapHover" />
    <div class="region-legend">
      <RegionLegendXAxis
        :data="regionsMapData"
        :options="options"
        @map-hover="onLegendHover"
        @scroll-position="onScrollPosition"
      />
      <RegionLegend
        :data="regionsMapData"
        :options="options"
        @map-hover="onLegendHover"
        @scroll-position="onScrollPosition"
      />
    </div>
  </div>
</template>

<script>
import RegionsMap from '../../d3new/RegionsMap'
import RegionLegend from '../../d3new/RegionLegend'
import RegionLegendXAxis from '../../d3new/RegionLegendXAxis'
import { mapGetters } from 'vuex'

const template = {
  i: 0, // Индекс
  w: 550, // Ширина
  h: 350, // Высота
  aw: false, // Автоматическая ширина
  t: 'unknown', // Тип
  m: { l: 20, t: 20, r: 20, b: 20 }, // Границы
  d: [], // Данные
  o: {}, // Опции
}

export default {
  data() {
    return {
      legendScrolable: false,
      bannerStyle: {
        paddingTop: '64px',
      },
      options: {
        scale: 0.7,
        width: 1000,
        height: 495,
        opacity: {
          default: 1,
          hover: 1,
          otherOnHover: 0.5,
        },
        strokeColor: 'grey',
        selectedIds: [],
        hoveredId: null,
      },
    }
  },
  components: {
    RegionsMap,
    RegionLegend,
    RegionLegendXAxis,
  },
  methods: {
    setHoveredRegion(regionId) {
      this.options.hoveredId = regionId
    },
    onMapHover(regionId) {
      this.legendScrolable = true
      this.setHoveredRegion(regionId)
    },
    onLegendHover(regionId) {
      this.legendScrolable = false
      this.setHoveredRegion(regionId)
    },
    onScrollPosition(top) {
      if (!this.legendScrolable) return
      const PADDING = 80
      this.legendScrollElement.scrollTop += top - this.legendElementTop - PADDING
    },
    setLegendElementHeight() {
      this.legendScrollElement.style.height = window.innerHeight - this.legendElementTop + 'px'
    },
  },
  computed: {
    ...mapGetters({
      plots: 'ly/getPlots',
      params: 'ly/getParams',
      plotsLength: 'ly/getPlotsCount',
      getRegionsData: 'ly2/getRegionsData',
      getRegionsAdditionals: 'ly2/getRegionsAdditionals',
    }),
    getRegionsPlot() {
      return {
        ...template,
        d: this.getRegionsData || [],
        w: 1000,
        h: 2200,
      }
    },
    regionsMapData() {
      let rmd = []
      if (this.getRegionsAdditionals && this.getRegionsAdditionals['regions']) {
        rmd = this.getRegionsAdditionals['regions'].map(d => {
          const r = this.getRegionsData.find(b => b.regionId === d.id)
          if (r) {
            return { ...d, regionId: d.id, value: +r.value }
          } else {
            return { ...d, regionId: d.id, value: 0 }
          }
        })
      }
      return rmd
    },
    legendScrollElement() {
      return this.$el.querySelector('.region-legend')
    },
    legendElementTop() {
      return this.legendScrollElement.getBoundingClientRect().top
    },
  },
}
</script>

<style scoped>
.region-legend {
  position: relative;
  overflow-y: scroll;
  scroll-behavior: smooth;
  max-width: 1020px;
  height: 275px;
}
</style>
