<template>
  <div>
    <TopMenu />

    <ParamsPanel>
      <SelectReason />
      <SelectSex />
      <SelectAge />
      <SelectPopulationGroup />
      <SelectYear />
    </ParamsPanel>

    <MainContent>
      <PageHeader title="Субъекты Российской Федерации" />
      <RegionsContent />
    </MainContent>
  </div>
</template>

<script>
import TopMenu from '../components/layout/TopMenu'
import ParamsPanel from '../components/layout/ParamsPanel'
import MainContent from '../components/layout/MainContent'
import RegionsContent from '../components/content/RegionsContent'
import PageHeader from '../components/layout/PageHeader'

import SelectSex from '../components/selects/SelectSex'
import SelectYear from '../components/selects/SelectYear'
import SelectPopulationGroup from '../components/selects/SelectPopulationGroup'
import SelectAge from '../components/selects/SelectAge'
import SelectReason from '../components/selects/SelectReason'

export default {
  name: 'ReGions',
  components: {
    TopMenu,
    ParamsPanel,
    MainContent,
    RegionsContent,
    SelectSex,
    SelectYear,
    SelectPopulationGroup,
    SelectAge,
    SelectReason,
    PageHeader,
  },
}
</script>
