<template>
  <v-select
    :label="label"
    :items="items"
    :value="value"
    item-value="id"
    item-text="name"
    @change="onChange"
    selectAllItem
    multiple
  >
    <!-- Select all -->
    <template v-slot:prepend-item>
      <v-list-item ripple @click="toggle">
        <v-list-item-action>
          <v-icon>
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>

    <!-- Nice display name -->
    <template v-slot:selection="{ index }">
      <span v-if="index === 0">
        {{ selectionText }}
      </span>
    </template>
  </v-select>
</template>

<script>
import collapseAgeDiapasonsToString from '../../../helpers/collapseAgeDiapasonsToString'

export default {
  props: ['label', 'items', 'value'],
  computed: {
    isAllSelected() {
      /*
        В value всегда возвращается на один элемент больше, чем количетсво выбранных элементов в списке
        Чтобы проверить что выбранны все, нужно сравнть длинну value - 1 со списком
      */
      return this.value.length === this.items.length
    },
    selectionText() {
      if (this.isAllSelected) return 'Все возраста'
      const selectedAgesNames = this.items.filter(d => this.value.includes(d.id)).map(d => d.name)
      return collapseAgeDiapasonsToString(selectedAgesNames)
    },
    icon() {
      if (this.isAllSelected) return 'mdi-close-box'
      if (this.value.length > 1) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    text() {
      if (this.isAllSelected) return 'Снять выбор всех'
      return 'Выбрать все'
    },
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
    toggle() {
      if (this.isAllSelected) {
        this.$emit('input', [])
      } else {
        /*
        массив [0, 1, 2, ... 19]
        Несколько странное решение, но пока не понимаю как по другому обыграть выбор всего
      */
        this.$emit(
          'input',
          [...Array(this.items.length).keys()].map(d => d + 1)
        )
      }
    },
  },
}
</script>
