<template>
  <v-select
    :label="label"
    :items="items"
    :value="value"
    item-value="id"
    item-text="name"
    @change="onChange"
  />
</template>
<script>

export default {
  props: ['label', 'items', 'value'],
  methods: {
    onChange(value) {
      this.$emit('input', value)
    }
  }
}
</script>
