<template>
  <div class="autocomplete-test">
    <v-autocomplete
      :label="label"
      :items="items"
      :value="value"
      item-value="id"
      item-text="name"
      @change="onChange"
    >
      <template v-slot:item="data">
        <div :class="'ma-2 ml-' + 4 * data.item.level">{{ data.item.name }}</div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: ['label', 'items', 'value'],
  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style>
.v-menu__content {
  max-width: 600px !important;
  max-height: 450px !important;
}
</style>
