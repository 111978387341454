<template>
  <SelectSelect label="Год" :items="yearItemsAdapter" v-model="value" />
</template>

<script>
import dataProviderMixin from './selectDataProvider'
import SelectSelect from './controlTypes/SelectSelect'

export default {
  mixins: [dataProviderMixin],
  data: () => ({
    paramName: 'year',
  }),
  components: {
    SelectSelect,
  },
  mounted() {
    const v = this.yearItemsAdapter

    if (!this.value && v.length) {
      this.value = v[v.length - 1].name
    }
  },
  watch: {
    yearItemsAdapter(v) {
      if (!this.value && v.length) {
        this.value = v[v.length - 1].name
      }
    },
  },
  computed: {
    // В качестве id нужен не id года, который приходит из словаря, а само числовое значение года
    yearItemsAdapter() {
      return this.getDictionaries.years.map(d => ({ id: d.value, name: d.value }))
    },
  },
}
</script>
