<template>
  <SelectSelect label="Тип населения" :items="populationGroupItemsAdapter" v-model="value" />
</template>

<script>
import dataProviderMixin from './selectDataProvider'
import SelectSelect from './controlTypes/SelectSelect'

export default {
  mixins: [dataProviderMixin],
  data: () => ({
    paramName: 'populationGroupId',
  }),
  components: {
    SelectSelect,
  },
  computed: {
    populationGroupItemsAdapter() {
      return this.getDictionaries.populationGroup.map(a => ({ ...a, name: a.comment }))
    },
  },
}
</script>
