<template>
  <div class="regions-legend-axis">
    <SvgSizeTemplate :width="width" :height="40" :margin="margin" />
  </div>
</template>

<script>
import { interpolateRdYlGn, scaleLinear, min, max, scaleBand, axisTop, axisLeft } from 'd3'

import * as topojson from 'topojson-client'
import mapJson from '../assets/russia.json'

import d3PlotMixin from './d3PlotMixin'
import d3SizeMixin from './d3SizeMixin'
import d3WatcherMixin from './d3WatchAndMountMixin'
import SvgSizeTemplate from './SvgSizeTemplate.vue'

export default {
  mixins: [d3PlotMixin, d3SizeMixin, d3WatcherMixin],
  components: {
    SvgSizeTemplate,
  },
  name: 'RegionLegendXAxis',
  data() {
    return {
      margin: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 240,
      },
    }
  },
  props: {
    data: {
      type: Array,
      requered: true,
    },
    width: {
      type: Number,
      default: 1000,
    },
    height: {
      type: Number,
      default: 1700,
    },
    barHeight: {
      type: Number,
      default: 18,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // Подготовка данных. Возможно метод для всех карт
    regions() {
      const regions = topojson.feature(mapJson, mapJson.objects['russia.2003']).features
      regions.forEach(region => {
        const f = this.data.find(r => r.iso_code === region.properties.iso_3166_2)
        region.value = f ? +f.value : 0
      })
      return regions
    },
    values() {
      const { data, regions } = this
      const values = regions.map(d => {
        const region = data.find(b => b.iso_code === d.properties.iso_3166_2)
        const v = d
        if (region) {
          v.value = region.value
        } else {
          v.value = 0
        }
        return v
      })
      return values
    },
    sortedValues() {
      return this.values.slice().sort((a, b) => {
        if (+a.value > +b.value) {
          return -1
        }
        if (+a.value < +b.value) {
          return 1
        }
        return 0
      })
    },

    // Цвет и шкалы скорее всего уникальный у каждого графика
    color() {
      return scaleLinear()
        .interpolate(() => d => interpolateRdYlGn(1 - d))
        .domain([
          min(this.values.map(d => +d.value)) || 0,
          max(this.values.map(d => +d.value)) || 1,
        ])
    },
    xScale() {
      return scaleLinear()
        .domain([0, max(this.values.map(d => +d.value)) || 1])
        .range([0, this.width - this.margin.left - this.margin.right])
    },
    yScale() {
      return scaleBand()
        .domain(this.sortedValues.map(d => d.properties.reg))
        .range([0, this.sortedValues.length * this.barHeight])
    },
  },
  methods: {
    createYAxis() {
      const { svg, yScale, animationDuration } = this
      svg.select('g.y-axis-left').transition().duration(animationDuration).call(axisLeft(yScale))
      svg.select('g.y-axis-left').select('.domain').remove()
    },
    createXAxis() {
      const { svg, xScale, sortedValues, barHeight, animationDuration } = this
      svg
        .select('g.x-axis-top')
        .transition()
        .duration(animationDuration)
        .call(axisTop(xScale).tickSize(-sortedValues.length * barHeight))
      svg.select('g.x-axis-top').select('.domain').remove()
    },
    createContent() {
      const { svg, sortedValues, color, xScale, barHeight, receiveOpacity } = this
      svg
        .select('g.content')
        .selectAll('rect')
        .data(sortedValues)
        .join(
          enter =>
            enter
              .append('rect')
              .attr('region-id', d => d.properties.iso_3166_2)
              .attr('x', 0)
              .attr('y', (d, i) => 5 + i * barHeight)
              .attr('height', barHeight - 10)
              .attr('width', d => xScale(d.value))
              .attr('fill', d => color(d.value))
              .attr('fill-opacity', d => receiveOpacity(d))
              .on('mouseover.internal', d => this.$emit('map-hover', d.properties.iso_3166_2))
              .on('mouseout.internal', () => this.$emit('map-hover', null)),
          update =>
            update
              .transition()
              .duration(this.animationDuration)
              .attr('y', (d, i) => 5 + i * barHeight)
              .attr('width', d => xScale(d.value))
              .attr('fill', d => color(d.value))
              .attr('fill-opacity', d => receiveOpacity(d))
        )
    },
    receiveOpacity(d) {
      const { opacity, hoveredId } = this.options
      if (hoveredId === null) return opacity.default
      return hoveredId === d.properties.iso_3166_2 ? opacity.hover : opacity.otherOnHover
    },
    setScroll() {
      if (this.options.hoveredId) {
        const top = this.svg
          .select(`rect[region-id="${this.options.hoveredId}"]`)
          ._groups[0][0].getBoundingClientRect().top
        this.$emit('scroll-position', top)
      } else {
        this.$emit('scroll-position', -1999)
      }
    },
    render() {
      if (!this.data) return
      this.createXAxis()
    },
  },
}
</script>

<style scoped>
svg >>> .tick line {
  stroke: #c0c0bb;
}
svg >>> .tick text {
  font-size: 1.2em;
  fill: #635f5d;
}
.regions-legend-axis {
  line-height: 0;
  position: sticky;
  top: 0;
  background-color: white;
}
</style>
