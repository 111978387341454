<template>
  <SelectSelect label="Пол" :items="sexItemsAdapter" v-model="value" />
</template>

<script>
import dataProviderMixin from './selectDataProvider'
import SelectSelect from './controlTypes/SelectSelect'

export default {
  mixins: [dataProviderMixin],
  data: () => ({
    paramName: 'sexId',
  }),
  components: {
    SelectSelect,
  },
  computed: {
    /*
      не удается использовать встроенный в миксин метод getItems
      т.к. разные названия полей sex — sexId
    */
    sexItemsAdapter() {
      return this.getDictionaries.sex
    },
  },
}
</script>
